
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CryptoCurrencyMarket, TickerTape } from 'react-ts-tradingview-widgets';
import Ad from '../components/Ad';
import BreadCrumb from '../components/BreadCrumb';

function Cripto() {

    const criptoSymbols = [
        {
          "proName": "BITSTAMP:BTCUSD",
          "title": "BTC/USD"
        },
        {
          "proName": "BITSTAMP:ETHUSD",
          "title": "ETH/USD"
        },
        {
            "proName": "BITSTAMP:USDTUSD",
            "title": "USDT/USD"
        },
        {
            "proName": "BITSTAMP:USDCUSD",
            "title": "USDC/USD"
        },
        {
            "proName": "BITSTAMP:XRPUSD",
            "title": "XRP/USD"
        },
        {
            "proName": "BITSTAMP:ADAUSD",
            "title": "ADA/USD"
        },
        {
            "proName": "BITSTAMP:SOLUSD",
            "title": "SOL/USD"
        },
        {
            "proName": "BITSTAMP:DOTUSD",
            "title": "DOT/USD"
        },
        {
            "proName": "BITSTAMP:MATICUSD",
            "title": "MATIC/USD"
        },
        {
            "proName": "BITSTAMP:DAIUSD",
            "title": "DAI/USD"
        },
        {
            "proName": "BITSTAMP:SHIBUSD",
            "title": "SHIB/USD"
        }
    ];

    useEffect(() => {
        window.scrollTo(0,0)
    },[]);

      /*
    const aa = document.getElementsByClassName("navigation-menu");

    if (aa.classList.contains('expanded')) {
        aa.classList.remove('expanded');
    }
    */
  
    // navigation-menu expanded


    return (
        <>
            <TickerTape
                colorTheme='light'
                locale='es'
                symbols={criptoSymbols}
            />            
            <Container>
                <Row>
                    <Col>
                        <BreadCrumb page='Cripto' />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>Cripto</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CryptoCurrencyMarket
                            colorTheme="light"
                            width="100%"
                            height={700}
                            locale='es'
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Cripto;