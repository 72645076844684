import React, { useState } from "react";
import { Link } from "react-router-dom";

// Navbar.js https://blog.logrocket.com/create-responsive-navbar-react-css/
import "./styles/navbar.css";

export default function Navbar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    return (
        <nav className="navigation">
          <Link to="/" className="brand-name" onClick={() => { setIsNavExpanded(false) }}>
            <img title='App' alt='App' src='/dividendos.jpeg' style={{height: '50px'}} />
          </Link>
          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded)
            }}
          >
            {/* icon from heroicons.com */}
            <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="white"
            >
            <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
            />
            </svg>
          </button>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >
            <ul>
              {/* <li>
                <Link to="/dashboard">Dashboard</Link>
              </li> */}
              <li>
                <Link 
                  onClick={() => { setIsNavExpanded(!isNavExpanded) }}
                  to="/companies"
                >
                  Companies
                </Link>
              </li>
              <li>
              <Link 
                  onClick={() => { setIsNavExpanded(!isNavExpanded) }}
                  to="/cripto"
                >
                  Cripto
                </Link>
              </li>
              {/* <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/settings">Settings</Link>
              </li> */}
            </ul>
          </div>
        </nav>
    );
}
