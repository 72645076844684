import React from "react";

const Footer = () => {
  return (
    <footer>
        <small>marketdata from tradingview</small>
        <small>v 0.6 &copy; Copyright 2022</small>
        <small><i>made with ♥ by dividendos.info</i></small>
    </footer>
  );
};

export default Footer;