
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Ad from '../components/Ad';

function Settings() {

    useEffect(() => {
        window.scrollTo(0,0)
    },[]);

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Settings</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Ad />
                </Col>
            </Row>
        </Container>
    );
}

export default Settings;