
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Ad from '../components/Ad';

function NoPage() {

    useEffect(() => {
        window.scrollTo(0,0)
    },[]);
    
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <h1 style={{textAlign: 'center'}}>Error 404. Página no encontrada</h1>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <p style={{textAlign: 'center', marginBottom: '100px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-error-404" width="200" height="200" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" id="IconChangeColor" transform="rotate(0)"> <path stroke="#4267b2" d="M0 0h24v24H0z" fill="#ffffff" id="mainIconPathAttribute" stroke-width="2"></path> <path d="M3 7v4a1 1 0 0 0 1 1h3" id="mainIconPathAttribute" stroke="#4267b2" fill="#ffffff"></path> <path d="M7 7v10" id="mainIconPathAttribute" stroke="#4267b2" fill="#ffffff"></path> <path d="M10 8v8a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1z" id="mainIconPathAttribute" stroke="#4267b2" fill="#ffffff"></path> <path d="M17 7v4a1 1 0 0 0 1 1h3" id="mainIconPathAttribute" stroke="#4267b2" fill="#ffffff"></path> <path d="M21 7v10" id="mainIconPathAttribute" stroke="#4267b2" fill="#ffffff"></path> <animateTransform href="#IconChangeColor" attributeType="xml" attributeName="transform" type="rotate" from="0" to="359" dur="10s" repeatCount="indefinite"></animateTransform></svg>
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default NoPage;