import React from "react";

import "../styles/ad.css";

const Ad = () => {

    return (
        <div className="ad-container">

        </div>
    );

}

export default Ad;