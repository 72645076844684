// https://www.tradingview.com/markets/stocks-usa/market-movers-large-cap/
export const usStocks = [
    {
        ticker: 'NASDAQ:AAPL',
        company: 'Apple Inc',
        logo: 'apple--big.svg'
    },
    {
        ticker: 'NASDAQ:MSFT',
        company: 'Microsoft Corp',
        logo: 'microsoft--big.svg'
    },
    {
        ticker: 'NASDAQ:GOOG',
        company: 'Alphabet Inc (Google)',
        logo: 'alphabet--big.svg'
    },
    {
        ticker: 'NASDAQ:AMZN',
        company: 'Amazon.com, Inc.',
        logo: 'amazon--big.svg'
    },
    {
        ticker: 'NASDAQ:TSLA',
        company: 'Tesla, Inc.',
        logo: 'tesla--big.svg'
    },
    {
        ticker: 'NYSE:BRK.A',
        company: 'Berkshire Hathaway Inc.',
        logo: 'berkshire-hathaway--big.svg'
    },
    {
        ticker: 'NYSE:UNH',
        company: 'UnitedHealth Group Incorporated',
        logo: 'unitedhealth--big.svg'
    },
    {
        ticker: 'NYSE:JNJ',
        company: 'Johnson & Johnson',
        logo: 'johnson-and-johnson--big.svg'
    },
    {
        ticker: 'NYSE:V',
        company: 'Visa Inc.',
        logo: 'visa--big.svg'
    },
    {
        ticker: 'NASDAQ:META',
        company: 'Meta Platforms, Inc.',
        logo: 'meta-platforms--big.svg'
    },
    {
        ticker: 'NASDAQ:NVDA',
        company: 'NVIDIA Corporation',
        logo: 'nvidia--big.svg'
    },
    {
        ticker: 'NYSE:XOM',
        company: 'Exxon Mobil Corporation',
        logo: 'exxon--big.svg'
    },
    {
        ticker: 'NYSE:PG',
        company: 'Procter & Gamble Company',
        logo: 'procter-and-gamble--big.svg'
    },
    {
        ticker: 'NYSE:WMT',
        company: 'Walmart Inc.',
        logo: 'walmart--big.svg'
    },
    {
        ticker: 'NYSE:JPM',
        company: 'JP Morgan Chase & Co.',
        logo: 'jpmorgan-chase--big.svg'
    },
    {
        ticker: 'NYSE:MA',
        company: 'Mastercard Incorporated',
        logo: 'mastercard--big.svg'
    },
    {
        ticker: 'NYSE:LLY',
        company: 'Eli Lilly and Company',
        logo: 'eli-lilly--big.svg'
    },
    {
        ticker: 'NYSE:HD',
        company: 'The Home Depot, Inc.',
        logo: 'home-depot--big.svg'
    },
    {
        ticker: 'NYSE:PFE',
        company: 'Pfizer, Inc',
        logo: 'pfizer--big.svg'
    },
    {
        ticker: 'NYSE:CVX',
        company: 'Chevron Corporation',
        logo: 'chevron--big.svg'
    },
    {
        ticker: 'NYSE:KO',
        company: 'The Coca-Cola Company',
        logo: 'coca-cola--big.svg'
    },
    {
        ticker: 'NYSE:ABBV',
        company: 'Abbvie Inc',
        logo: 'abbvie--big.svg'
    },
    {
        ticker: 'NYSE:BAC',
        company: 'Bank of America Corporation',
        logo: 'bank-of-america--big.svg'
    },
    {
        ticker: 'NASDAQ:PEP',
        company: 'Pepsico, Inc',
        logo: 'pepsico--big.svg'
    },
    {
        ticker: 'NYSE:MRK',
        company: 'Merck & Company, Inc',
        logo: 'merck-and-co--big.svg'
    },
    {
        ticker: 'NASDAQ:COST',
        company: 'Costco WHolesale Corporation',
        logo: 'costco-wholesale--big.svg'
    },
    {
        ticker: 'NYSE:TMO',
        company: 'Thermo Fisher Scientific Inc',
        logo: 'thermo-fisher-scientific--big.svg'
    },
    {
        ticker: 'NASDAQ:AVGO',
        company: 'Broadcom Inc.',
        logo: 'broadcom--big.svg'
    },
    {
        ticker: 'NYSE:DHR',
        company: 'Danaher Corporation',
        logo: 'danaher--big.svg'
    },
    {
        ticker: 'NYSE:ORCL',
        company: 'Oracle Corporation',
        logo: 'oracle--big.svg'
    },
    {
        ticker: 'NASDAQ:CMCSA',
        company: 'Comcast Corporation',
        logo: 'comcast--big.svg'
    },
    {
        ticker: 'NYSE:ABT',
        company: 'Abbott Laboratories',
        logo: 'abbott--big.svg'
    },
    {
        ticker: 'NYSE:MCD',
        company: `McDonald's Corporation`,
        logo: 'mcdonalds--big.svg'
    },
    {
        ticker: 'NYSE:VZ',
        company: 'Verizon Communications Inc.',
        logo: 'verizon--big.svg'
    },
    {
        ticker: 'NYSE:DIS',
        company: 'The Walt Disney Company',
        logo: 'walt-disney--big.svg'
    },
    {
        ticker: 'NASDAQ:CSCO',
        company: 'Cisco System, Inc.',
        logo: 'cisco--big.svg'
    },
    {
        ticker: 'NYSE:ACN',
        company: 'Accenture plc',
        logo: 'accenture--big.svg'
    },
    {
        ticker: 'NASDAQ:ADBE',
        company: 'Adobe Inc.',
        logo: 'adobe--big.svg'
    },
    {
        ticker: 'NYSE:CRM',
        company: 'Salesforce, Inc.',
        logo: 'salesforce--big.svg'
    },
    {
        ticker: 'NASDAQ:QCOM',
        company: 'QUALCOMM Incorporated',
        logo: 'qualcomm--big.svg'
    },
    {
        ticker: 'NASDAQ:TMUS',
        company: 'T-Mobile US, Inc.',
        logo: 't-mobile--big.svg'
    },
    {
        ticker: 'NYSE:NKE',
        company: 'Nike, Inc.',
        logo: 'nike--big.svg'
    },
    {
        ticker: 'NYSE:WFC',
        company: 'Wells Fargo & Company',
        logo: 'wells-fargo--big.svg'
    },
    {
        ticker: 'NASDAQ:INTC',
        company: 'Intel Corporation',
        logo: 'intel--big.svg'
    },
    {
        ticker: 'NYSE:UPS',
        company: 'United Parecel Service, Inc.',
        logo: 'united-parcel--big.svg'
    },
    {
        ticker: 'NYSE:NEE',
        company: 'NextEra Energy, Inc.',
        logo: 'nextera-energy--big.svg'
    },
    {
        ticker: 'NYSE:BMY',
        company: 'Bristol-Myers Squibb Company',
        logo: 'bristol-myers-squibb--big.svg'
    },
    {
        ticker: 'NYSE:PM',
        company: 'Philip Morris International Inc.',
        logo: 'philip-morris--big.svg'
    },
    {
        ticker: 'NASDAQ:TXN',
        company: 'Texas Instruments Incorporated',
        logo: 'texas-instruments--big.svg'
    },
    {
        ticker: 'NYSE:LIN',
        company: 'Linde plc',
        logo: 'linde--big.svg'
    },
    {
        ticker: 'NYSE:MS',
        company: 'Morgan Stanley',
        logo: 'morgan-stanley--big.svg'
    },
    {
        ticker: 'NASDAQ:AMD',
        company: 'Advanced Micro Devices Inc',
        logo: 'advanced-micro-devices--big.svg'
    },
    {
        ticker: 'NYSE:UNP',
        company: 'Union Pacific Corporation',
        logo: 'union-pacific--big.svg'
    },
    {
        ticker: 'NYSE:RTX',
        company: 'Raytheon Technologies Corporation',
        logo: 'raytheon--big.svg'
    },
    {
        ticker: 'NASDAQ:AMGN',
        company: 'Amgen Inc.',
        logo: 'amgen--big.svg'
    },
    {
        ticker: 'NYSE:T',
        company: 'AT&T Inc.',
        logo: 'at-and-t--big.svg'
    },
    {
        ticker: 'NYSE:CVS',
        company: 'CVS Health Corporation',
        logo: 'cvs-health--big.svg'
    },
    {
        ticker: 'NYSE:SPGI',
        company: 'S&P Global Inc.',
        logo: 's-and-p-global--big.svg'
    },
    {
        ticker: 'NASDAQ:HON',
        company: 'Honeywell International Inc.',
        logo: 'honeywell--big.svg'
    },
    {
        ticker: 'NYSE:MDT',
        company: 'Medtronic plc',
        logo: 'medtronic--big.svg'
    },
    {
        ticker: 'NYSE:LOW',
        company: `Lowe's Companies, Inc.`,
        logo: 'lowe-s--big.svg'
    },
    {
        ticker: 'NYSE:COP',
        company: 'ConocoPhillips',
        logo: 'conocophillips--big.svg'
    },
    {
        ticker: 'NASDAQ:INTU',
        company: 'Intuit Inc.',
        logo: 'intuit--big.svg'
    },
    {
        ticker: 'NYSE:SCHW',
        company: 'The Charles Schwab Corporation',
        logo: 'schwab--big.svg'
    },
    {
        ticker: 'NYSE:IBM',
        company: 'International Business Machines Company',
        logo: 'international-bus-mach--big.svg'
    },
    {
        ticker: 'NYSE:ELV',
        company: 'Elevance Health, Inc.',
        logo: 'anthem--big.svg'
    },
    {
        ticker: 'NYSE:AXP',
        company: 'American Express Company',
        logo: 'american-express--big.svg'
    },
    {
        ticker: 'NYSE:BX',
        company: 'Blackstone Inc,',
        logo: 'blackstone--big.svg'
    },
    {
        ticker: 'NYSE:GS',
        company: 'Goldman Sachs Group, Inc.',
        logo: 'goldman-sachs--big.svg'
    },
    {
        ticker: 'NYSE:LMT',
        company: 'Lockheed Martin Corporation',
        logo: 'lockheed-martin--big.svg'
    },
    {
        ticker: 'NYSE:C',
        company: 'Citigroup, Inc',
        logo: 'citigroup--big.svg'
    },
    {
        ticker: 'NYSE:DE',
        company: 'Deere & Company',
        logo: 'deere--big.svg'
    },
    {
        ticker: 'NYSE:CAT',
        company: 'Caterpillar, Inc.',
        logo: 'caterpillar--big.svg'
    },
    {
        ticker: 'NASDAQ:NFLX',
        company: 'Netflix, Inc.',
        logo: 'netflix--big.svg'
    },
    {
        ticker: 'NYSE:BLK',
        company: 'BlackRock, Inc.',
        logo: 'blackrock--big.svg'
    },
    {
        ticker: 'NYSE:BA',
        company: 'Boeing Companny',
        logo: 'boeing--big.svg'
    },
    {
        ticker: 'NASDAQ:SBUX',
        company: 'Starbucks Corporation',
        logo: 'starbucks--big.svg'
    },
    {
        ticker: 'NASDAQ:ADP',
        company: 'Automatic Data Processing, Inc.',
        logo: 'automatic-data-processing--big.svg'
    },
    {
        ticker: 'NYSE:EL',
        company: 'Estee Lauder Companies, Inc.',
        logo: 'estee-lauder--big.svg'
    },
    {
        ticker: 'NASDAQ:PYPL',
        company: 'Paypal Holdings, Inc.',
        logo: 'paypal--big.svg'
    },
    {
        ticker: 'NYSE:CI',
        company: 'Cigna Corporation',
        logo: 'cigna--big.svg'
    },
    {
        ticker: 'NASDAQ:MDLZ',
        company: 'Mondelez International, Inc.',
        logo: 'mondelez--big.svg'
    },
    {
        ticker: 'NASDAQ:AMAT',
        company: 'Applied Materials, Inc.',
        logo: 'applied-materials--big.svg'
    },
    {
        ticker: 'NYSE:NOW',
        company: 'ServiceNow, Inc.',
        logo: 'servicenow--big.svg'
    },
    {
        ticker: 'NASDAQ:ADI',
        company: 'Analog Devices, Inc.',
        logo: 'analog-devices--big.svg'
    },
    {
        ticker: 'NYSE:ZTS',
        company: 'Zoetis Inc.',
        logo: 'zoetis--big.svg'
    },
    {
        ticker: 'NYSE:DUK',
        company: 'Duke Energy Corporation',
        logo: 'duke-energy--big.svg'
    },
    {
        ticker: 'NASDAQ:CHTR',
        company: 'Charter Communications, Inc',
        logo: 'charter--big.svg'
    },
    {
        ticker: 'NYSE:MMM',
        company: '3M Company',
        logo: '3m--big.svg'
    },
    {
        ticker: 'NYSE:MO',
        company: 'Altria Group, Inc.',
        logo: 'altria--big.svg'
    },
    {
        ticker: 'NYSE:CB',
        company: 'Chubb Limited',
        logo: 'chubb--big.svg'
    },
    {
        ticker: 'NYSE:GE',
        company: 'General Electric Company',
        logo: 'general-electric--big.svg'
    },
    {
        ticker: 'NYSE:MMC',
        company: 'Marsh & McLenna Companies, Inc',
        logo: 'marsh-and-mclennan--big.svg'
    },
    {
        ticker: 'NYSE:SO',
        company: 'Southern Company',
        logo: 'southern--big.svg'
    },
    {
        ticker: 'NASDAQ:ISRG',
        company: 'Intuitive Surgical, Inc',
        logo: 'intuitive-surgical--big.svg'
    },
    {
        ticker: 'NYSE:BAM',
        company: 'Bookfield Asset Management Inc.',
        logo: 'brookfield--big.svg'
    },
    {
        ticker: 'NYSE:SYK',
        company: 'Stryker Corporation',
        logo: 'stryker--big.svg'
    },
    {
        ticker: 'NASDAQ:GILD',
        company: 'Gilead Sciences, Inc.',
        logo: 'gilead--big.svg'
    },
    {
        ticker: 'NASDAQ:VRTX',
        company: 'Vertex Pharmaceuticals Incorporated',
        logo: 'vertex-pharmaceutical--big.svg'
    },
    {
        ticker: 'NASDAQ:CME',
        company: 'CME Group, Inc.',
        logo: 'cme--big.svg'
    } 
];

// https://www.tradingview.com/markets/stocks-spain/market-movers-large-cap/
export const esStocks = [
    {
        ticker: 'BME:ITX',
        company: 'Inditex',
        logo: 'inditex--big.svg'
    },
    {
        ticker: 'BME:IBE',
        company: 'Iberdrola',
        logo: 'iberdrola--big.svg'
    },
    {
        ticker: 'BME:SAN',
        company: 'Banco Santander',
        logo: 'santander--big.svg'
    },
    {
        ticker: 'BME:CLNX',
        company: 'Cellnex Telecom',
        logo: 'cellnex-telecom--big.svg'
    },
    {
        ticker: 'BME:BBVA',
        company: 'Banco Bilbao Vizcaya Argentaria',
        logo: 'banco-bilbao-vizcaya-argentaria--big.svg'
    },
    {
        ticker: 'BME:NTGY',
        company: 'Naturgy Energy Group',
        logo: 'naturgy--big.svg'
    },
    {
        ticker: 'BME:TEF',
        company: 'Telefónica',
        logo: 'telefonica--big.svg'
    },
    {
        ticker: 'BME:AMS',
        company: 'Amadeus IT Group',
        logo: 'amadeus--big.svg'
    },
    {
        ticker: 'BME:CABK',
        company: 'Caixabank',
        logo: 'caixabank--big.svg'
    },
    {
        ticker: 'BME:FER',
        company: 'Ferrovial',
        logo: 'ferrovial--big.svg'
    },
    {
        ticker: 'BME:AENA',
        company: 'AENA',
        logo: 'aena--big.svg'
    },
    {
        ticker: 'BME:ELE',
        company: 'Endesa',
        logo: 'endesa--big.svg'
    },
    {
        ticker: 'BME:REP',
        company: 'Repsol',
        logo: 'repsol--big.svg'
    },
    {
        ticker: 'BME:ANE',
        company: 'Corporacion Acciona Energias Renovables',
        logo: 'corporacion-acciona--big.svg'
    },
    {
        ticker: 'BME:SGRE',
        company: 'Siemens Gamesa Renewable Energy',
        logo: 'siemens-gamesa--big.svg'
    },
    {
        ticker: 'BME:ANA',
        company: 'Acciona, S.A.',
        logo: 'acciona--big.svg'
    },
    {
        ticker: 'BME:RED',
        company: 'Red Eléctrica Corporación, S.A.',
        logo: 'red-electrica--big.svg'
    },
    {
        ticker: 'BME:GRF',
        company: 'Grifols S.A.',
        logo: 'grifols--big.svg'
    },
    {
        ticker: 'BME:ACS',
        company: 'Actividades de Construcción y Servicios, S.A.',
        logo: 'acs-actividades-de-construccion-y-servicios--big.svg'
    },
    {
        ticker: 'BME:ENG',
        company: 'Enagas, S.A.',
        logo: 'enagas--big.svg'
    },
    {
        ticker: 'BME:MAP',
        company: 'Mapfre, S.A.',
        logo: 'mapfre--big.svg'
    },
    {
        ticker: 'BME:BKT',
        company: 'Bankinter, S.A.',
        logo: 'bankinter--big.svg'
    },
    {
        ticker: 'BME:FCC',
        company: 'Acciones Fomento de Construcciones y Contratas, S.A.',
        logo: 'fomento-de-construcciones-y-contratas--big.svg'
    },
    {
        ticker: 'BME:FDR',
        company: 'Fluidra, S.A.',
        logo: 'fluidra--big.svg'
    },
    {
        ticker: 'BME:SAB',
        company: 'Banco de Sabadell',
        logo: 'banco-de-sabadell--big.svg'
    },
    {
        ticker: 'BME:GCO',
        company: 'Grupo Catalana Occidente, S.A.',
        logo: 'grupo-catalana-occidente--big.svg'
    },
    {
        ticker: 'BME:ROVI',
        company: 'Laboratorios Farmaceuticos Rovi, S.A.',
        logo: 'laboratorios-farmaceuticos--big.svg'
    },
    {
        ticker: 'BME:CIE',
        company: 'CIE Automotive, S.A.',
        logo: 'cie-automotive--big.svg'
    },
    {
        ticker: 'BME:ALB',
        company: 'Corporación Financiera Alba, S.A.',
        logo: 'corporacion-financiera-alba--big.svg'
    },
    {
        ticker: 'BME:VIS',
        company: 'Viscofan, S.A.',
        logo: 'viscofan--big.svg'
    },
    {
        ticker: 'BME:SLR',
        company: 'Solaria Energia y Medio Ambiente, S.A.',
        logo: 'solaria-energia-y-medio-ambiente--big.svg'
    },
    {
        ticker: 'BME:LOG',
        company: 'Compañia de Distribución Integral Logista Holdings, S.A.',
        logo: 'logista--big.svg'
    },
    {
        ticker: 'BME:EBRO',
        company: 'Ebro Foods, S.A.',
        logo: 'ebro-foods--big.svg'
    },
    {
        ticker: 'BME:ACX',
        company: 'Acerinox, S.A.',
        logo: 'acerinox--big.svg'
    },
    {
        ticker: 'BME:UNI',
        company: 'Unicaja Banco, S.A.',
        logo: 'unicaja-banco--big.svg'
    },
    {
        ticker: 'BME:GEST',
        company: 'Gestamp Automocion, S.A.',
        logo: 'gestamp-automocion--big.svg'
    },
    {
        ticker: 'BME:VID',
        company: 'Vidrala, S.A.',
        logo: 'vidrala--big.svg'
    }
];