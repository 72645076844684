import React from "react";
import { Link } from "react-router-dom";

import "../styles/breadcrumb.css";

const BreadCrumb = ({symbol, page, country}: any) => {
    return (
        <ul className='breadcrumb'>
            <li>
                <Link to={`/`}>
                    App
                </Link>
            </li>
            {
                symbol && country ?
                <>
                    <li>
                        <Link to={`/companies/`} >
                            Companies
                        </Link>
                    </li>
                    <li>
                        <Link to={`/companies/${country}`} >
                            {country.toUpperCase()}
                        </Link>
                    </li>
                    <li>
                        {symbol}
                    </li>
                </>
                :
                ''
            }
            {
                page && country
                ?
                <>
                    <li>
                        <Link to={`/companies/`}>
                            Companies
                        </Link>
                    </li>
                    <li>
                        {country.toUpperCase()}
                    </li>
                </>
                :
                ''
            }
            {
                page && !country
                ?
                <li>
                    {page}
                </li>
                :
                ''
            }
        </ul>
    );
};

export default BreadCrumb;