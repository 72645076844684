import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import Ad from '../components/Ad';
import BreadCrumb from '../components/BreadCrumb';

import { usStocks, esStocks } from '../data/companies';

const Companies = () => {

    const params = useParams();
    const country = params.country;

    useEffect(() => {
        window.scrollTo(0,0)
    },[]);

    if ( country ) {

        const dataCountry = country === 'spain' ? esStocks : usStocks;
        
        return (
            <Container>
                <Row>
                    <Col>
                        <BreadCrumb page='Companies' country={country} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1 style={{'textAlign':'center'}}>Empresas de {country}</h1>
                        <table>
                            <thead>
                                <tr>
                                    <th>Logo</th>
                                    <th>Empresa</th>
                                    <th>Ticker</th>
                                    <th>Índice</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                            dataCountry.map(i => {
                                const index = i.ticker.includes('NASDAQ') ? 'NASDAQ' : 'NYSE';
                                return (
                                        <tr>
                                            <td>
                                                <Link to={`/stock/${i.ticker}/`} key={i.company}>
                                                    <img style={{'borderRadius':'10%','margin':'5px'}} src={`https://s3-symbol-logo.tradingview.com/${i.logo}`} alt={i.company} title={i.company} />
                                                </Link>
                                            </td>
                                            <td><b>{i.company}</b></td>
                                            <td>{i.ticker}</td>
                                            <td>{index}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BreadCrumb page='Companies' country={country} />
                    </Col>
                </Row>
            </Container>
        );
    } else {
        return (
            <Container>
                <Row>
                    <Col>
                        <BreadCrumb page='Companies' />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1 style={{'textAlign':'center'}}>Top 100 empresas por capitalización USA</h1>
                        {
                            usStocks.map(i => {
                                return (
                                    <Link to={`/stock/${i.ticker}/`} key={i.company}>
                                        <img style={{'borderRadius':'10%','margin':'5px'}} src={`https://s3-symbol-logo.tradingview.com/${i.logo}`} alt={i.company} title={i.company} />
                                    </Link>
                                )
                            })
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1 style={{'textAlign':'center'}}>Top 100 empresas por capitalización España</h1>
                        {
                            esStocks.map(i => {
                                return (
                                    <Link to={`/stock/${i.ticker}/`} key={i.company}>
                                        <img style={{'borderRadius':'10%','margin':'5px'}} src={`https://s3-symbol-logo.tradingview.com/${i.logo}`} alt={i.company} title={i.company} />
                                    </Link>
                                )
                            })
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BreadCrumb page='Companies' />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Companies;