import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Ad from '../components/Ad';

function About() {

    useEffect(() => {
        window.scrollTo(0,0)
    },[]);
    
    return (
        <Container>
            <Row>
                <Col>
                    <h1>About</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Ad />
                </Col>
            </Row>
        </Container>
    );
}

export default About;