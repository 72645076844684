import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import About from './pages/About';
import Cripto from './pages/Cripto';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import Layout from './pages/Layout';
import NoPage from './pages/NoPage';
import Settings from './pages/Settings';
import Stock from './pages/Stock';
import Companies from './pages/Companies';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/companies" element={<Companies />}>
            <Route path="/companies/:country" element={<Companies />} />
          </Route>
          <Route path="/cripto" element={<Cripto />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/stock" element={<Stock />}>
            <Route path="/stock/:stockId" element={<Stock />} />
          </Route>
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
