import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";

import { useParams } from 'react-router-dom'
import { CompanyProfile, FundamentalData, SymbolInfo, SymbolOverview } from "react-ts-tradingview-widgets";
import Ad from "../components/Ad";
import BreadCrumb from '../components/BreadCrumb';

const Stock = () => {

    const params = useParams()
    const ticker = params.stockId;
    const country = ticker && ticker.includes('BME') ? 'spain' : 'usa';

    useEffect(() => {
        window.scrollTo(0,0)
    },[]);
    
    if (ticker) {
        return (
            <Container>
                {/* {
                    !ticker.includes('BME')
                    ?
                    // eslint-disable-next-line react/style-prop-object
                    <AdvancedRealTimeChart theme='light' symbol={ticker} height={600} width='100%' locale='es'></AdvancedRealTimeChart>
                    :
                    null
                } */}
                <Row>
                    <Col>
                        <BreadCrumb symbol={ticker} country={country} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SymbolInfo colorTheme='light' symbol={ticker} autosize locale='es'></SymbolInfo>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SymbolOverview colorTheme='light'
                            locale='es'
                            height={600}
                            width='100%'
                            symbols={[[ticker]]}
                            chartType='area'
                            scalePosition='right'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FundamentalData colorTheme='light' symbol={ticker} height={800} width='100%' locale='es' largeChartUrl='https://app.dividendos.info/graph'></FundamentalData>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CompanyProfile colorTheme='light' symbol={ticker} height={800} width="100%" locale='es' largeChartUrl='https://app.dividendos.info/graph'></CompanyProfile>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BreadCrumb symbol={ticker} country={country} />
                    </Col>
                </Row>
            </Container>
        );
    } else {
        return <h1>vista normal</h1>
    }
};

export default Stock;