import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TickerTape, MiniChart } from "react-ts-tradingview-widgets";

import { Container, Row, Col } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import Ad from '../components/Ad';

import { usStocks, esStocks } from '../data/companies';

/*
    Row = 12 cols or 100%
    <Container>
        <Row>
            <Col lg={4}>This is one column</Col>
            <Col lg={4}>This is second column</Col>
            <Col lg={4}>This is third column</Col>
        </Row>
        <hr></hr>
        <Row>
            <Col md={3} sm={6} xs={6}>This is one column</Col>
            <Col md={3} sm={6} xs={6}>This is second column</Col>
            <Col md={3} sm={6} xs={6}>This is third column</Col>
            <Col md={3} sm={6} xs={6}>This is four column</Col>
        </Row>
    </Container>
*/

const Home = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    },[]);

    return (
        <>
            <TickerTape colorTheme='light' locale='es'></TickerTape>
            <Container>
                <Row>
                    <Col xl={2}>
                        <Ad />
                    </Col>
                    <Col xl={8}>
                        <MiniChart colorTheme='light' locale='es' width="100%"></MiniChart>
                    </Col>
                    <Col xl={2}>
                        <Ad />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Ad />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <h1 style={{'textAlign':'center'}}>Top 30 USA</h1>
                        {
                            usStocks.map((i, idx) => {
                                if(idx < 30)
                                    return (
                                        <Link to={`/stock/${i.ticker}/`} key={i.company}>
                                            <img style={{'borderRadius':'10%','margin':'5px'}} src={`https://s3-symbol-logo.tradingview.com/${i.logo}`} alt={i.company} title={i.company} />
                                        </Link>
                                    )
                            })
                        }
                    </Col>
                    <Col xs={12} md={6}>
                        <h1 style={{'textAlign':'center'}}>Top 30 España</h1>
                        {
                            esStocks.map((i, idx) => {
                                if(idx < 30)
                                    return (
                                        <Link to={`/stock/${i.ticker}/`} key={i.company}>
                                            <img style={{'borderRadius':'10%','margin':'5px'}} src={`https://s3-symbol-logo.tradingview.com/${i.logo}`} alt={i.company} title={i.company} />
                                        </Link>
                                    )
                            })
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Home;